<template>
  <div class="custom-santa">
    <div class="d-flex justify-content-between top-btn align-items-center">
      <div class="mr-1">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block v-b-modal.create_ss variant="outline-primary" pill
          clss="p-1" v-if="!closed && ($can('Zircly Admin') || $can('secret_santa_create'))">
          Create Secret Santa Program
        </b-button>
      </div>
      <div>
        <audio id="audio" style="display: none" :src="require('@/assets/audio/audio.mp3')" controls autoplay loop></audio>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent" class="p-0 bg-transparent"
          v-if="!closed && ($can('Zircly Admin') || $can('secret_santa_create'))" @click="closed = true"
          v-b-tooltip.hover.v-dark v-b-tooltip.hover.top="'Hide Program List'">
          <feather-icon icon="XIcon" stroke="#6E6B7B" />
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent" class="p-0 bg-transparent"
          v-if="closed && ($can('Zircly Admin') || $can('secret_santa_create'))" @click="closed = false"
          v-b-tooltip.hover.v-dark v-b-tooltip.hover.top="'Show Program List'">
          <feather-icon icon="Maximize2Icon" stroke="#6E6B7B" />
        </b-button>

        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent" class="p-0 bg-transparent ml-1"
          v-if="$can('secret_santa_access')" v-b-tooltip.hover.v-dark v-b-tooltip.hover.top="'Show Rules'"
          v-b-modal.secret_santa_rules>
          <feather-icon icon="InfoIcon" stroke="#6E6B7B" />
        </b-button>

        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent" class="p-0 bg-transparent ml-1"
          v-if="!muted" @click="muteAudio()" v-b-tooltip.hover.v-dark v-b-tooltip.hover.top="'Mute Audio'">
          <feather-icon icon="Volume2Icon" stroke="#38B22D" />
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent" class="p-0 bg-transparent ml-1"
          v-if="muted" @click="unmuteAudio()" v-b-tooltip.hover.v-dark v-b-tooltip.hover.top="'Un-Mute Audio'">
          <feather-icon icon="VolumeXIcon" stroke="#ea5455" />
        </b-button>
      </div>
    </div>

    <b-row v-if="!closed && ($can('Zircly Admin') || $can('secret_santa_create'))">
      <b-col md="12" class="d-flex justify-content-start">
        <!-- <b-avatar
          square
          style="background-color: transparent"
          v-b-tooltip.hover.v-default
          title="Secret Santa Program"
          :to="{ name: 'secret-santa' }"
          :src="require('@/assets/images/secret-santa/santa-claus.png')"
        /> -->
        <!-- <b-avatar
          square
          style="background-color: transparent"
          v-b-tooltip.hover.v-default
          title="Secret Santa Program"
          :to="{ name: 'secret-santa' }"
          :src="require('@/assets/images/secret-santa/christmas-tree.png')"
        /> -->
      </b-col>
    </b-row>
    <b-row v-if="onGoingProgram && onGoingProgram.isUserParticipate.status == 'Yes' && onGoingProgram.chain" class="mt-1">
    <!-- <b-row  class="mt-1"> -->
      <b-col md="12" class="d-flex justify-content-center">
        <!-- <b-avatar square style="background-color: transparent" v-b-tooltip.hover.v-dark title="Secret Santa Program"
          :to="{ name: 'secret-santa' }" :src="require('@/assets/images/secret-santa/santa-claus.png')" /> -->
        <div class="mr-1">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block v-b-tooltip.hover v-b-modal.modal-reveal
            variant="outline-primary" pill>
            Who is My Secret Santa? Reveal Now !!!
          </b-button>
        </div>
        <!-- <b-avatar square style="background-color: transparent" v-b-tooltip.hover.v-dark title="Secret Santa Program"
          :to="{ name: 'secret-santa' }" :src="require('@/assets/images/secret-santa/christmas-tree.png')" /> -->
      </b-col>
    </b-row>

    <b-modal id="secret_santa_rules" ref="secret_santa_rules" ok-only centered size="lg" cancel-variant="outline-danger"
      :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="next-expand d-flex align-items-center justify-content-between w-100">
          <h3 class="mb-0"> Welcome to Secret Santa with Zircly!</h3>
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Maximize"
              :src="require('@/assets/images/icons/center-expand.svg')" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Minimize"
              :src="require('@/assets/images/icons/right-expand.svg')" />
          </b-link>
        </div>
        <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
      </template>
      <b-card-body class="p-0">
        <b-row>
          <b-col md="12" class="">
            <div>
              <div class="ml-0 mt-1">
                <p>
                  Please note that you must be a user to participate in the
                  game.
                </p>
                <h4 class="mb-1">Game Instructions:</h4>
                <p>
                  <b>1.</b> Sign up to play the game, when registrations open.
                </p>
                <p>
                  <b>2.</b> Make sure you have your wish list ready. Have 3-5
                  items on your wishlist, and do not let the items exceed the gift limit.
                </p>
                <p>
                  <b>3.</b> Once you sign up to play the game, add in all
                  relevant details such as your contact information including
                  your delivery address, and contact number. Please note that
                  this will be shared with your Secret Santa, so they can send
                  you your gift.
                </p>
                <p>
                  <b>4.</b> Once registrations close, and the game begins, use
                  Zircly to pick your Little Elf.
                </p>
                <p>
                  <b>5.</b> You can ANONYMOUSLY Chat with your Little Elf or
                  assign them a task on zircly. Assign tasks, such as "Share a
                  bad rendition of Wild Flower by RM of BTS" to help them
                  identify you." A task such as this will help your Little Elf
                  narrow their options from everyone in the community, to those
                  who listen to BTS or are fans of K-Pop music.
                </p>
                <p>
                  <b>6.</b> You have until the reveal day to give them tasks and
                  clues.
                </p>
                <p>
                  <b>7.</b> In the meanwhile, use your Little Elf's wishlist to
                  pick a gift for them, and have it delivered to their address.
                  If you can, schedule the delivery for the Reveal Day.
                </p>
                <p>
                  <b>8.</b> Use the Secret Santa Community to share any tasks or
                  gifts you are given and include everyone in the fun.
                </p>
                <p>
                  <b>9.</b> Do your best to also identify your Secret Santa
                  through their tasks and clues.
                </p>
                <p>
                  <b>10.</b> On reveal day, you will be prompted to guess your
                  Secret Santa, and your Little Elf will try to do the same. We
                  hope you get it right :wink:
                </p>
              </div>
              <small>For more questions about the game, reach out to members of the
                Zircly Team, or the HR Team.</small>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-modal>

    <b-modal id="create_ss" ref="create_ss" ok-title="Create" centered size="lg" @ok="formSubmitted" @show="resetModal"
      @hidden="resetModal" cancel-variant="outline-danger"
      :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="next-expand d-flex align-items-center justify-content-between w-100">
          <h3 class="mb-0">Create Secret Santa Program</h3>
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Maximize"
              :src="require('@/assets/images/icons/center-expand.svg')" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Minimize"
              :src="require('@/assets/images/icons/right-expand.svg')" />
          </b-link>
        </div>
        <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
      </template>
      <!-- <b-card no-body class="secret-santa-bg-img2 overlay-text"> -->
      <b-card-body class="p-0">
        <b-row style="1opacity: 0.8">
          <b-col md="12" class="mt-1">
            <b-form-group>
              <label class="font-weight-bold">Start Date</label>
              <flat-pickr v-model="program.start_date" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="12" class="">
            <b-form-group>
              <label class="font-weight-bold">Sign Up Deadline</label><small> Final date by which individuals must enroll
                or register for the program</small>
              <flat-pickr v-model="program.sign_up_dead_line" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="12" class="">
            <label class="font-weight-bold">Price Limit on Gift</label>
            <b-input-group prepend="$" append=".00">
              <b-form-input v-model="program.gift_price_limit" />
            </b-input-group>
          </b-col>

          <b-col md="12" class="mt-2">
            <b-form-checkbox v-model="program.is_postal" value="true">
              <h6>Gift Exchange By Postal</h6>
            </b-form-checkbox>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group>
              <label class="font-weight-bold">Invitation Type</label>
              <v-select v-model="program.invitation_type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invitation_options" :reduce="(label) => label.code" label="label" :clearable="true"
                input-id="invitation_type" placeholder="Select Invitation Type.." />
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-1" v-if="program.invitation_type == 'invite_by_email'">
            <b-form-group>
              <label class="font-weight-bold">
                Participants Email : Enter all emails by adding comma(,).
              </label>
              <!-- <b-form-textarea
                id="emails"
                v-model="program.emails"
                name="email"
                placeholder="Email"
              /> -->
              <b-form-tags id="emails" v-model="program.emails" name="email" separator=" ,;"
                placeholder="Enter new Emails separated by space, comma or semicolon" no-add-on-enter class="mb-2" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <!-- </b-card> -->
    </b-modal>
    <b-modal id="edit_ss" ref="edit_ss" ok-title="Update" centered size="lg" @ok="formUpdated" @show="resetModal"
      @hidden="resetModal" cancel-variant="outline-danger"
      :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="next-expand d-flex align-items-center justify-content-between w-100">
          <h3 class="mb-0">Update Secret Santa Program</h3>
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Maximize"
              :src="require('@/assets/images/icons/center-expand.svg')" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Minimize"
              :src="require('@/assets/images/icons/right-expand.svg')" />
          </b-link>
        </div>
        <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
      </template>
      <!-- <b-card no-body class="secret-santa-bg-img1 overlay-text1"> -->
      <b-card-body class="p-0">
        <b-row style="1opacity: 0.8">
          <b-col md="12" class="mt-1">
            <b-form-group>
              <label class="font-weight-bold">Start Date</label>
              <flat-pickr v-model="selectedProgram.start_date" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="12" class="">
            <b-form-group>
              <label class="font-weight-bold">Sign Up Deadline</label><small> Final date by which individuals must enroll
                or register for the program</small>
              <flat-pickr v-model="selectedProgram.sign_up_dead_line" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="12" class="">
            <label class="font-weight-bold">Price Limit on Gift</label>
            <b-input-group prepend="$" append=".00">
              <b-form-input v-model="selectedProgram.gift_price_limit" />
            </b-input-group>
          </b-col>

          <b-col md="12" class="mt-2">
            <b-form-checkbox v-model="selectedProgram.is_postal" value="true">
              <h6>Gift Exchange By Postal</h6>
            </b-form-checkbox>
          </b-col>
          <b-col md="12" class="mt-1">
            <b-form-group>
              <label class="font-weight-bold">Invitation Type</label>
              <v-select v-model="selectedProgram.invitation_type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="invitation_options" :reduce="(label) => label.code" label="label" :clearable="true"
                input-id="invitation_type" placeholder="Select Invitation Type.." />
            </b-form-group>
          </b-col>
          <b-col md="12" class="" v-if="selectedProgram.invitation_type == 'invite_by_email'">
            <b-form-group>
              <label class="font-weight-bold">
                Participants Email : Enter all emails by adding comma(,).
              </label>
              <!-- <b-form-textarea
              id="emails"
              v-model="program.emails"
              name="email"
              placeholder="Email"
            /> -->
              <b-form-tags id="emails" v-model="selectedProgram.emails" name="email" separator=" ,;"
                placeholder="Enter new Emails separated by space, comma or semicolon" no-add-on-enter class="mb-2" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <!-- </b-card> -->
    </b-modal>
    <b-modal v-if="onGoingProgram" id="enrollment" ref="enrollment" ok-title="Update" centered size="lg"
      :title="'Secret Santa Program ' + onGoingProgram.year" @ok="enrollMe" cancel-variant="outline-danger">
      <!-- <b-modal
      v-if="onGoingProgram"
      id="enrollment"
      ref="enrollment"
      ok-title="Update"
      centered
      size="lg"
      @ok="enrollMe"
      cancel-variant="outline-danger"
    :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top"
    >
    <template #modal-header="{ close }">
                <div class="next-expand d-flex align-items-center justify-content-between w-100">
                  <h3 class="mb-0">Secret Santa Program onGoingProgram.year</h3>
                  <b-link class="fullscreen-icon"
                    @click="fullScreen = !fullScreen">
                    <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')"  />
                            <b-avatar @click="fullScreen = !fullScreen" v-else square style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                            title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')"  />
                  </b-link>
                </div>
                <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
              </template> -->
      <b-row v-if="onGoingProgram.is_postal == 'true'">
        <b-col md="12">
          <h4 class="my-1">Your Delivery Address</h4>
        </b-col>
        <b-col md="6">
          <b-form-group label="Address Line 1" label-for="address-line-1">
            <b-form-input id="address-line-1" v-model="enrollment.delivery_address.add_line_1" type="text"
              placeholder="Address Line 1" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Address Line 2" label-for="address-line-2">
            <b-form-input id="address-line-2" v-model="enrollment.delivery_address.add_line_2" type="text"
              placeholder="Address Line 2" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Postcode" label-for="postcode">
            <b-form-input id="postcode" v-model="enrollment.delivery_address.post_code" type="text"
              placeholder="Postcode" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="City" label-for="city">
            <b-form-input id="city" v-model="enrollment.delivery_address.city" type="text" placeholder="City" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="State" label-for="state">
            <b-form-input id="state" v-model="enrollment.delivery_address.state" type="text" placeholder="State" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Country" label-for="country">
            <b-form-input id="country" v-model="enrollment.delivery_address.country" type="text" placeholder="Country" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <h3>Your Wishlist</h3>
          <span v-if="onGoingProgram.gift_price_limit">Please note that gift price limit is
            ${{ onGoingProgram.gift_price_limit }}</span>
          <p>
            Created your gift wishlist, it will be visible to your secret santa
            by magic. you might get what you want. all the best.
          </p>
          <p>
            To avoid geting random gifts please add gifts that are in gift
            price limit
          </p>
        </b-col>
      </b-row>
      <b-row v-for="(wish, k) in wishlist" :key="k" class="bg-transparent">
        <b-col md="12" class="add-wishlist">
          <b-form-group>
            <label class="required-l" for="name">Wish #{{ k + 1 }}</label>
            <b-form-input id="wishs" v-model="wishlist[k]" name="wishs" placeholder="Enter Wish" />
          </b-form-group>
          <b-form-group class="w-100 text-right mb-0">
            <b-button v-if="k > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
              class="btn-icon rounded-circle ml-2" @click="removeWish(k)">
              <feather-icon icon="XIcon" />
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="bg-transparent">
        <b-col md="12" class="add-morewishlist">
          <b-form-group>
            <b-button
              class="add-options d-flex align-items-center justify-content-end w-100 py-0 pl-1 pr-0 bg-transparent border-0"
              @click="addWish">
              <feather-icon icon="PlusIcon" size="15" /> <label for="name" class="mb-0 ml-50">Add More Wish</label>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="task-update" ref="task-update" ok-title="Update Task" centered size="lg" @ok="updateTask"
      cancel-variant="outline-danger" @cancel="resetModal" @close="resetModal"
      :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="next-expand d-flex align-items-center justify-content-between w-100">
          <h3 class="mb-0">Give a task to your Little Elf</h3>
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Maximize"
              :src="require('@/assets/images/icons/center-expand.svg')" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Minimize"
              :src="require('@/assets/images/icons/right-expand.svg')" />
          </b-link>
        </div>
        <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
      </template>
      <b-row>
        <b-col md="12">
          <!-- <h3>Task Deatils</h3> -->
        </b-col>
        <b-col md="12">
          <b-form-group label="Task Title" label-for="title">
            <b-form-input id="title" v-model="task.title" type="text" placeholder="Example - Buy Toothbrush" required />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Task Description" label-for="description">
            <QuillInput :value.sync="task.description"
              placeholder="Run to nearby store and buy a toothbrush and upload a picture of it. :)" :toolbar="true"
              className="task-editor" :mentionList="[]" required />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Task Status" label-for="status">
            <div class="demo-inline-spacing justify-content-start mt-50">
              <b-form-radio v-model="task.status" name="staus" value="not-started-yet" class="custom-control-primary">
                Not Started Yet
              </b-form-radio>

              <b-form-radio v-model="task.status" name="staus" value="in-progress" class="custom-control-primary">
                In Progress
              </b-form-radio>

              <b-form-radio v-model="task.status" name="staus" value="completed" class="custom-control-primary">
                Completed
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="task-file-upload" ref="task-file-upload" ok-title="Upload" centered size="lg" @ok="uploadFiles"
      cancel-variant="outline-danger" @cancel="resetFileModal" @close="resetFileModal"
      :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="next-expand d-flex align-items-center justify-content-between w-100">
          <h3 class="mb-0">Upload Task Files</h3>
          <b-link class="fullscreen-icon" @click="fullScreen = !fullScreen">
            <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Maximize"
              :src="require('@/assets/images/icons/center-expand.svg')" />
            <b-avatar @click="fullScreen = !fullScreen" v-else square
              style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark title="Minimize"
              :src="require('@/assets/images/icons/right-expand.svg')" />
          </b-link>
        </div>
        <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="20" @click="close()" />
      </template>
      <b-row>
        <b-col md="12">
          <h4>
            If the task given by your secret santa is completed. you can upload
            pictures,videos of it (if the task invloves)
          </h4>
        </b-col>
        <b-col md="12">
          <b-form-group label="Attachments" label-for="files">
            <file-pond ref="pond" name="files[]" max-file-size="50MB" allow-paste="false" max-files="5"
              allow-multiple="true" v-bind:files="myFiles" image-preview-height="120px"
              accepted-file-types="image/*,video/*,application/pdf"
              file-validate-type-label-expected-types="Expects a PDF, Images, Video file" />
            <small class="text-secondary flex items-center notes">
              File size should be less than 50MB and allowed
              formats are ( .mp4,.pdf, image)</small>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-reveal"
      ref="modal-reveal"
      ok-only
      ok-title="Close"
      centered
      hide-header
      hide-footer
      size="lg"
      style="background: transparent"
    >
    <!-- Custom Design For reveal Screen -->
    <b-row>
      <b-col md="12" class="reveal-name p-0">
        <div class="game-start">
          <feather-icon class="cursor-pointer floating-close close" icon="XIcon" size="16" @click="closeReveal()" />
          <!-- Indivual Screen Design -->
          <div class="inner-content w-100 unique-chain" v-if="is_ready == false">
            <b-img :src="require('@/assets/images/secret-santa/Thank-playing.svg')" class="reveal-bg"></b-img>
            <div class="inner-text text-center">
              <h2>Thanks You for Playing</h2>
              <p>The cat is about to be out of the bag. Are you ready too see if you guessed it right?</p>
              <b-button
                class="mt-1 text-danger"
                href="#"
                variant="light"
                @click="revealNow()"
                pill
                >I’m Ready</b-button
              >
            </div>
          </div>
          <!-- <div class="inner-content w-100 d-none" v-if="onGoingProgram && onGoingProgram.chain">
            <b-img :src="require('@/assets/images/secret-santa/name-banner.svg')"></b-img>
            <div class="inner-text text-center">
              <h2>Kalaiselvi C</h2>
              <h4 class="mb-3 text-white">kalaiselvic@dckap.com</h4>
            </div>
          </div> -->
          <!-- End Indivual Screen Design -->
          <!-- <div v-if="onGoingProgram && onGoingProgram.chain"> -->
          <!-- Whole Chain Screen Design -->
          <div>
            <div class="inner-content w-100 whole-chain" v-if="is_ready == true">
          <!-- <div class="inner-content w-100 whole-chain" v-for="(link, k) in onGoingProgram.chain" :key="k"> -->
            <b-img :src="require('@/assets/images/secret-santa/wholechain.svg')" class="reveal-bg"></b-img>
            <div class="chain-reveal inner-text pb-5">
              <div class="text-center">
                <h3 class="mb-0 pt-3">Revealed!</h3>
                <h6 class="text-white mb-1">Discover your Secret Santa and explore others as well</h6>
              </div>
              <b-card>
                <div class="your-santa text-center d-flex align-items-center justify-content-center">
                  <b-img :src="require('@/assets/images/secret-santa/santa-icon.svg')" size="20px" class="mx-25"/>
                  <h4 class="mb-0"><span class="parent-reveal">{{ onGoingProgram.chain[userData.hashid].user.name }}</span> is Your Secret Santa</h4>
                  <b-img :src="require('@/assets/images/secret-santa/santa-icon.svg')" size="20px" class="mx-25"/>
                </div>
                <div class="others-santa">
                  <ul class="m-0 p-0">
                    <li v-for="(link, k) in onGoingProgram.chain" :key="k">
                      <span class="text-dark font-weight-bolder">
                        <b-avatar size="18px" :src="link.user.avatar" />
                        <span class="pl-25 text" v-b-tooltip.hover.v-dark :title="link.user.name">{{link.user.name}}</span>
                      </span>
                      <span class="text"> is Secret Santa for </span>
                      <span class="text-dark font-weight-bolder">
                        <b-avatar size="18px" :src="link.picked_user.avatar" /> 
                        <span class="pl-25 text" v-b-tooltip.hover.v-dark :title="link.picked_user.name">{{link.picked_user.name}}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </b-card>
            </div>
          </div>
          </div>
           <!-- End Whole Chain Screen Design -->
      </div>
      </b-col>
    </b-row>
      <!-- <b-card-text>Check out who is your secret santa and you can also check others too.</b-card-text>
      <h1 class="d-flex justify-content-center">My Secret Santa</h1>
      <b-row v-if="onGoingProgram && onGoingProgram.chain" class="d-flex justify-content-center">
        <b-col md="6">
          <b-row class="mt-1">
            <b-col md="2">
              <b-avatar size="70px" class="ml-2" :src="onGoingProgram.chain[userData.hashid].user.avatar" />
            </b-col>
            <b-col md="8">
              <h2
                style="margin: 0;position: absolute;top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);">
                {{ onGoingProgram.chain[userData.hashid].user.name }} is your Secret Santa</h2>
            </b-col>
            <b-col md="2">
              <b-avatar size="70px" class="mr-2" :src="onGoingProgram.chain[userData.hashid].picked_user.avatar" />
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->
      <!-- Whole chain -->
      <!-- <h1 class="d-flex justify-content-center mt-1">Whole Chain</h1>
      <b-row v-if="onGoingProgram && onGoingProgram.chain" class="mt-1">
        <b-col md="6" v-for="(link, k) in onGoingProgram.chain" :key="k">
          <b-row class="mt-1">
            <b-col md="2">
              <b-avatar size="70px" class="ml-2" :src="link.user.avatar" />
            </b-col>
            <b-col md="8">
              <h2
                style="margin: 0;position: absolute;top: 50%;-ms-transform: translateY(-50%);transform: translateY(-50%);">
                {{ link.user.name }} is Secret Santa for {{ link.picked_user.name }}</h2>
            </b-col>
            <b-col md="2">
              <b-avatar size="70px" class="mr-2" :src="link.picked_user.avatar" />
            </b-col>
          </b-row>
        </b-col>
      </b-row> -->
    </b-modal>
    <!-- New Child Task Status View Popup -->
    <b-modal id="task-status" ref="task-update" ok-title="Close" ok-only centered size="lg"
      :modal-class="fullScreen ? 'modal-full' : 'modal-responsive-width'" header-class="sticky-top">
      <template #modal-header="{ close }">
        <div class="next-expand d-flex align-items-center justify-content-between w-100">
          <h4 class="mb-0">Task Details</h4>
          <div class="header-right d-flex align-items-center">
            <!-- Badge Update-->
            <!-- <b-badge
                    class="ml-auto"
                    variant="light-danger"
                    v-if="task.status == 'not-started-yet' && task.status != 'completed' && task.is_accepted != 'rejected'"
                  >
                    Not Started
                  </b-badge> -->
            <b-badge class="ml-auto" variant="light-warning"
              v-if="task.status == 'in-progress' && task.status != 'completed'">
              In Progress
            </b-badge>
            <b-badge class="ml-auto" variant="light-primary" v-if="task.status == 'completed'">
              Completed
            </b-badge>
            <b-badge class="ml-1" variant="light-info" v-if="task.is_published == 'published'">
              Published
            </b-badge>
            <b-badge class="ml-1" variant="warning" v-if="task.is_accepted == null">
              Not Accepted Yet
            </b-badge>
            <b-badge class="ml-1" variant="primary" v-if="task.is_accepted == 'accepted' && task.status != 'completed'">
              Accepted
            </b-badge>
            <b-badge class="ml-1" variant="danger" v-if="task.is_accepted == 'rejected'">
              Rejected
            </b-badge>
            <!-- End Badge Update -->
            <b-link class="fullscreen-icon ml-75" @click="fullScreen = !fullScreen">
              <b-avatar @click="fullScreen = !fullScreen" v-if="!fullScreen" square
                style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                title="Maximize" :src="require('@/assets/images/icons/center-expand.svg')" />
              <b-avatar @click="fullScreen = !fullScreen" v-else square
                style="background-color: transparent; width: 34px; height: 34px;" v-b-tooltip.hover.v-dark
                title="Minimize" :src="require('@/assets/images/icons/right-expand.svg')" />
            </b-link>
          </div>
        </div>
        <feather-icon class="cursor-pointer floating-close close p-0" icon="XIcon" size="20" @click="close()" />
      </template>
      <b-row>
        <b-col md="12">
          <h3>{{ task.title }}</h3>
        </b-col>
        <b-col md="12">
          <b-card-text class="p-0" v-html="task.description"></b-card-text>
        </b-col>
        <b-col md="12" class="attch-status py-2 mt-2 mb-0">
          <b-form-group label="Attachments from Your Little Elf" label-for="attchments" class="mb-0">
            <div class="attched-file mt-1">
              <ul class="p-0 mb-0">
                <li v-for="file in task.files" @click="openFile(file)">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g>
                        <path
                          d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                          stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>{{ file.filename }}</span>
                  </p>
                </li>
                <li v-if="!task.files || task.files.length == 0">
                  <p>No attachments yet</p>
                </li>
              </ul>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <!-- End -->

    <b-row class="mb-2">
      <b-col md="12">
        <!-- Table Container Card -->
        <b-card v-if="!closed && $can('Zircly Admin')" no-body class="mb-0 mt-2">
          <div class="m-2">
            <!-- Table Top -->
            <b-row class="mt-0">
              <!-- Per Page -->
              <b-col cols="12" md="6" class="
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-1 mb-md-0
                ">
                <b-form-checkbox v-if="secret_santa_programsMetaData.length &&
                  $can('secret_santa_program_delete')
                  " class="mr-2" v-model="isDeleteMultipleCandChecked">
                </b-form-checkbox>
                <label>Show</label>
                <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                  :clearable="false" :searchable="false" class="per-page-selector d-inline-block mx-50" />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div v-if="selectedSecretSantaPrograms.length == 0" class="d-flex align-items-center justify-content-end">
                  <div class="mr-0">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent"
                      class="btn-icon bg-transparent border-0" @click="closed = true">
                      <feather-icon icon="XIcon" stroke="#6E6B7B" size="26" />
                    </b-button>
                  </div>
                  <!-- <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Search Subject"
                    />
                  </b-input-group> -->

                  <!-- <b-button
                            v-if="$can('secret_santa_program_create')"
                            variant="primary"
                            :to="{ name: 'add-secret_santa_program' }"
                            >
                            <span class="text-nowrap">Add Secret Santa Program</span>
                            </b-button> -->
                  <b-button v-if="$can('secret_santa_program_create')" variant="primary" @click="addNew()"
                    v-b-toggle.add-sidebar>
                    <span class="text-nowrap">New Secret Santa Program</span>
                  </b-button>
                </div>
                <b-button v-else-if="$can('secret_santa_program_delete')" class="float-right" variant="danger"
                  :disabled="secret_santa_programsDeleteCount == 0" @click="confirmDeleteMultipleRecords">
                  <span class="text-nowrap">Delete {{ secret_santa_programsDeleteCount }} Secret Santa
                    Program</span>
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-form-checkbox-group id="checkbox-group" v-model="selectedSecretSantaPrograms"
            name="selectedSecretSantaPrograms">
            <b-table ref="refUserListTable" class="position-relative" :items="fetchSecretSantaProgramList" responsive
              :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
              empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
              <template #cell(select)="data">
                <div class="text-nowrap">
                  <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
                </div>
              </template>
              <!-- Column: User -->
              <template #cell(support_category.name)="data">
                <b-media vertical-align="center">
                  <b-link v-if="$can('secret_santa_program_show')" v-b-toggle.secret_santa_program-view-sidebar
                    @click="viewRecord(data)" class="font-weight-bold d-inline-block text-nowrap" v-b-tooltip.hover.v-dark
                    :title="data.item.support_category.name">
                    {{ data.item.support_category.name | str_limit(15) }}
                  </b-link>
                  <span v-else v-b-tooltip.hover.topright.v-primary :title="data.item.support_category.name">
                    {{ data.item.support_category.name | str_limit(15) }}
                  </span>
                </b-media>
              </template>

              <!--Column: Status -->
              <template #cell(status)="data">
                <b-badge pill :variant="`light-${resolveUserStatusVariant(
                  data.item.status
                )}`" class="text-capitalize">
                  {{ data.item.status }}
                </b-badge>
              </template>

              <template #cell(created_at)="data">
                {{ data.item.created_at | formatDate }}
              </template>

              <template #cell(subject)="data">
                {{ data.item.subject | str_limit(15) }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <feather-icon @click="editRecord(data)" v-if="$can('secret_santa_edit')" v-b-tooltip.hover
                  v-b-toggle.add-sidebar title="Edit" icon="EditIcon" size="17" class="mr-1 cursor-pointer" />

                <feather-icon v-b-tooltip.hover v-b-modal.modal-reveal title="View Secret Santa Chain" icon="LinkIcon"
                  size="17" class="mr-1 cursor-pointer" v-if="onGoingProgram && onGoingProgram.chain" />

                <b-dropdown variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="17" class="text-body align-middle mr-25" />
                  </template>

                  <!-- <b-dropdown-item
                    class="btn-sm p-0"
                    @click="editRecord(data)"
                    v-b-toggle.add-sidebar
                    v-if="$can('secret_santa_edit')"
                  >
                    <feather-icon icon="EditIcon" size="13" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item> -->

                  <!-- <b-dropdown-item
                    class="btn-sm p-0"
                    v-b-toggle.secret_santa_program-view-sidebar
                    @click="viewRecord(data)"
                    v-if="$can('secret_santa_show')"
                  >
                    <feather-icon icon="EyeIcon" size="13" class="mr-50" />
                    <span>View</span>
                  </b-dropdown-item> -->

                  <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(data)"
                    v-if="$can('secret_santa_delete')">
                    <feather-icon icon="TrashIcon" size="17" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-form-checkbox-group>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col cols="12" sm="6" class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                ">
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col cols="12" sm="6" class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                ">
                <b-pagination v-if="selectedSecretSantaPrograms.length == 0" v-model="currentPage"
                  :total-rows="totalSecretSantaProgramList" :per-page="perPage" first-number last-number
                  class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
                <b-button v-else-if="$can('secret_santa_program_delete')" class="float-right" variant="danger"
                  :disabled="secret_santa_programsDeleteCount == 0" @click="confirmDeleteMultipleRecords">
                  <span class="text-nowrap">Delete {{ secret_santa_programsDeleteCount }} Secret Santa
                    Program</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="onGoingProgram" class="d-flex justify-content-center">
      <b-col md="12" class="before-start">
        <!-- <b-card
          :title="'Secret Santa Program ' + onGoingProgram.year"
          :img-src="
            require('@/assets/images/secret-santa/secret_santa_enroll.png')
          "
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2"
          sub-title="What Is Secret Santa?"
        >
          <b-card-text>
            Secret Santa is a Christmas tradition. Members of a group of
            friends, family, or coworkers draw random names to become someone’s
            Secret Santa. The Secret Santa is given a wish list of gift ideas to
            choose from to give to their chosen giftee. After opening their
            present, the giftee has to guess which member of the group was their
            Secret Santa. It’s a holiday classic where the guessing is as much a
            part of the fun as receiving the gift.
          </b-card-text>
          <b-row class="d-flex justify-content-center">
            <b-badge variant="light-primary">
              Game Starts In :
              <Countdown :deadline="onGoingProgram.deadline"></Countdown>
            </b-badge>
          </b-row>
          <b-row class="d-flex justify-content-center">
            <b-button
              v-if="
                onGoingProgram.isUserParticipate.status == 'No' &&
                onGoingProgram.is_postal == 'true'
              "
              class="mt-1 w-50"
              href="#"
              variant="outline-danger"
              v-b-modal.enrollment
              pill
              >Count Me In</b-button
            >
            <b-button
              v-if="
                onGoingProgram.isUserParticipate.status == 'No' &&
                onGoingProgram.is_postal == 'false'
              "
              class="mt-1 w-50"
              href="#"
              variant="outline-danger"
              @click="enrollMe"
              pill
              >Count Me In</b-button
            >
            <b-badge
              v-if="onGoingProgram.isUserParticipate.status == 'Yes'"
              variant="primary"
              class="badge-glow mt-1"
            >
              <feather-icon icon="CheckCircleIcon" class="mr-50" />
              <span class="align-middle">Already Enrolled</span>
            </b-badge>
            <b-badge
              v-if="onGoingProgram.isUserParticipate.status == 'Yes'"
              variant="danger"
              class="badge-glow mt-1 ml-2"
              v-b-modal.enrollment
            >
              <feather-icon icon="CheckCircleIcon" class="mr-50" />
              <span class="align-middle">Add Wishlist</span>
            </b-badge>
          </b-row>
        </b-card> -->
        <!-- Before Game Start Blur Top Content -->
        <b-col class="game-start" sm="12 p-0" v-if="onGoingProgram.isUserParticipate.status == 'No'">
          <b-row class="blur-div py-5 my-2">
            <b-col md="7" sm="12">
              <!-- <p class="text-santa d-none d-lg-block mb-2 pb-2">Game Is Not Started Yet, Before the game starts, </br> please take a look at the <b-link class="text-danger" v-b-modal.secret_santa_rules>rules</b-link></p>
          <p class="text-santa d-lg-none">Game Is Not Started Yet, Before the game starts, please take a look at the <b-link class="text-danger" v-b-modal.secret_santa_rules>rules</b-link></p> -->
              <div class="bg-timer position-relative" v-if="onGoingProgram.is_started == false">
                <div class="inner-time">
                  <h4>Game Starts In</h4>
                  <Countdown :deadline="onGoingProgram.deadline"> </Countdown>
                </div>
              </div>
              <div class="bg-timer position-relative" v-if="onGoingProgram.is_started == true">
                <div class="inner-time">
                  <h4>Game Already Started</h4><br>
                  <h4>Don't worry you can still join.</h4>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center enroll-btn">
                <div class="d-flex align-items-center btn-show mt-3" v-if="onGoingProgram.isUserParticipate.status == 'No' &&
                  onGoingProgram.is_postal == 'true'
                  ">
                  <b-button class="w-50" href="#" variant="outline-danger" v-b-modal.secret_santa_rules pill>Read The
                    Rules</b-button>
                  <b-button class="w-50" href="#" variant="dark" v-b-modal.enrollment pill>Join The Fun</b-button>
                </div>
                <div class="d-flex align-items-center btn-show mt-3" v-if="onGoingProgram.isUserParticipate.status == 'No' &&
                  onGoingProgram.is_postal == 'false'
                  ">
                  <b-button class="w-50" href="#" variant="outline-danger" v-b-modal.secret_santa_rules pill>Read The
                    Rules</b-button>
                  <b-button class="w-50" href="#" variant="dark" @click="enrollMe" pill>Join The Fun</b-button>
                </div>
                <div class="bg-dark already-enrolled w-100 pb-2" v-if="onGoingProgram.is_started == false && onGoingProgram.isUserParticipate.status == 'Yes'">
                  <h6 class="mt-25">Ready {{ userData.name }}?</h6>
                  <p class="my-2">We’ll let you know once the game begins.</p>
                  <div class="d-flex align-items-center btn-enrol mb-50">
                    <b-button class="w-50" href="#" variant="outline-light" v-b-modal.secret_santa_rules pill>Read
                      Rules</b-button>
                    <b-button class="w-50" href="#" variant="light" v-b-modal.enrollment pill>Start Building
                      Wishlist</b-button>
                  </div>
                </div>
                <!-- <b-badge
              v-if="onGoingProgram.isUserParticipate.status == 'Yes'"
              variant="transparent"
              class="badge-glow bg-transparent text-primary"
            >
              <span class="align-middle mr-75">You’re already Enrolled</span>
              <feather-icon icon="CheckIcon" class="" width="24px" height="24px" />
            </b-badge>
            <b-badge
              v-if="onGoingProgram.isUserParticipate.status == 'Yes'"
              variant="transparent"
              pill
              class="badge-glow mt-1 btn-outline-danger w-auto"
              v-b-modal.enrollment
            >
              <span class="align-middle">Update Your Wishlist and Delivery Address</span>
            </b-badge> -->
              </div>
            </b-col>
            <b-col md="5" sm="12" class="text-right mt-4">
              <b-img :src="require('@/assets/images/secret-santa/Christmas-game.svg')"></b-img>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="game-start" sm="12 p-0" v-if="onGoingProgram.isUserParticipate.status == 'Yes' && onGoingProgram.is_started == false">
          <b-row class="blur-div py-5 my-2">
            <b-col md="7" sm="12">
              <!-- <p class="text-santa d-none d-lg-block mb-2 pb-2">Game Is Not Started Yet, Before the game starts, </br> please take a look at the <b-link class="text-danger" v-b-modal.secret_santa_rules>rules</b-link></p>
          <p class="text-santa d-lg-none">Game Is Not Started Yet, Before the game starts, please take a look at the <b-link class="text-danger" v-b-modal.secret_santa_rules>rules</b-link></p> -->
              <div class="bg-timer position-relative" v-if="onGoingProgram.is_started == false">
                <div class="inner-time">
                  <h4>Game Starts In</h4>
                  <Countdown :deadline="onGoingProgram.deadline"> </Countdown>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center enroll-btn">
                <div class="bg-dark already-enrolled w-100 pb-2">
                  <h6 class="mt-25">Ready {{ userData.name }}?</h6>
                  <p class="my-2">We’ll let you know once the game begins.</p>
                  <div class="d-flex align-items-center btn-enrol mb-50">
                    <b-button class="w-50" href="#" variant="outline-light" v-b-modal.secret_santa_rules pill>Read
                      Rules</b-button>
                    <b-button class="w-50" href="#" variant="light" v-b-modal.enrollment pill>Start Building
                      Wishlist</b-button>
                  </div>
                </div>
                <!-- <b-badge
              v-if="onGoingProgram.isUserParticipate.status == 'Yes'"
              variant="transparent"
              class="badge-glow bg-transparent text-primary"
            >
              <span class="align-middle mr-75">You’re already Enrolled</span>
              <feather-icon icon="CheckIcon" class="" width="24px" height="24px" />
            </b-badge>
            <b-badge
              v-if="onGoingProgram.isUserParticipate.status == 'Yes'"
              variant="transparent"
              pill
              class="badge-glow mt-1 btn-outline-danger w-auto"
              v-b-modal.enrollment
            >
              <span class="align-middle">Update Your Wishlist and Delivery Address</span>
            </b-badge> -->
              </div>
            </b-col>
            <b-col md="5" sm="12" class="text-right mt-4">
              <b-img :src="require('@/assets/images/secret-santa/Christmas-game.svg')"></b-img>
            </b-col>
          </b-row>
        </b-col>
        <!-- End Before Game Start Blur Top Content -->
      </b-col>
    </b-row>
    <b-row v-if="onGoingProgram == null" class="d-flex justify-content-center">
      <b-col md="7">
        <b-card title="No Secret Santa Program Found" :img-src="require('@/assets/images/secret-santa/empty-dark.png')
          " img-alt="Image" img-top tag="article" class="mb-2"
          sub-title="Ask your admin to get the ball rolling.Reach out to them now!">
          <b-card-text>
            Secret Santa is a Christmas tradition. Members of a group of
            friends, family, or coworkers draw random names to become someone’s
            Secret Santa. The Secret Santa is given a wish list of gift ideas to
            choose from to give to their chosen giftee. After opening their
            present, the giftee has to guess which member of the group was their
            Secret Santa. It’s a holiday classic where the guessing is as much a
            part of the fun as receiving the gift.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row
      v-if="
        onGoingProgram &&
        onGoingProgram.is_started == true &&
        onGoingProgram.isUserParticipate.status == 'No' &&
        onGoingProgram.isUserParticipate.secret_child == null
      "
      class="d-flex justify-content-center"
    >
      <b-col md="7">
        <b-card
          title="Sorry, Game is already started !"
          :img-src="require('@/assets/images/secret-santa/santa-tata.png')"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2"
          sub-title="Game Already Started?"
        >
          <b-card-text>
            Game is already started. we are very sorry we missed you. Everyone
            drawed names. You can spectate the game in Secret santa community
            post. See you next year.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row> -->
    <b-row v-if="onGoingProgram &&
      onGoingProgram.is_started == true &&
      onGoingProgram.isUserParticipate.status != 'No' &&
      onGoingProgram.isUserParticipate.secret_child == null
      " class="d-flex justify-content-center">
      <b-col md="7">
        <b-card title="Draw a name and become secret santa !" :img-src="require('@/assets/images/secret-santa/secret_santa_enroll.png')
          " img-alt="Image" img-top tag="article" class="mb-2" sub-title="Why Draw Names?">
          <b-card-text>
            Drawing names is required to randomly assign a Secret Santa to each
            player. This is the fun part; Click "Draw a name" and find out whose
            Christmas you’ll be making better! just make sure you keep things
            straight with a top secret , Be a good secret santa and don't reveal
            your little elf to anyone. Good luck and have fun!.
          </b-card-text>

          <b-row class="d-flex justify-content-center">
            <b-button v-if="onGoingProgram.isUserParticipate.status == 'Yes'" class="mt-1 w-50" href="#" variant="danger"
              pill @click="Draw">Draw a Name</b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="onGoingProgram &&
      onGoingProgram.is_started == true &&
      onGoingProgram.isUserParticipate.secret_child != null
      ">
      <!-- <b-col lg="12" md="12" class="santa-tabs"> -->
      <!-- Before Game Start Blur Top Content  -->
      <b-col lg="12" md="12" class="santa-tabs">
        <!-- Before Game Start Blur Top Content -->
        <b-tabs content-class="mt-2" justified pills active-nav-item-class="font-weight-bold">
          <b-tab active>
            <template #title>
              <strong>My Little Elf</strong>
            </template>
            <b-row v-if="onGoingProgram &&
              onGoingProgram.is_started == true &&
              onGoingProgram.isUserParticipate.secret_child != null
              ">
              <b-col lg="8" md="12">
                <b-row class="m-0 bg-white pb-25">
                  <b-col lg="6" md="6" class="pl-0 left-profile">
                    <child-profile class="bg-white" :child="onGoingProgram.isUserParticipate.secret_child"
                      :program="onGoingProgram" @refresh-task-list="tasksList" />
                  </b-col>
                  <b-col lg="6" md="6" class="pl-0 given-task">
                    <!-- <b-breadcrumb :items="items" /> -->
                    <b-row v-if="tasks" class="bg-white flex-column">
                      <h4 class="py-1 py-50 mt-50">Given Tasks</h4>
                      <b-col lg="12" class="task-height pl-0">
                        <b-card class="shadow-none bg-santa mb-1" v-for="taskIndex in tasks" v-b-modal.task-status
                          @click="task = taskIndex">
                          <b-card-header class="px-1 pt-1 pb-0">
                            <!-- <b-card-title
                          v-b-tooltip.hover.v-default
                          v-b-tooltip.hover.right="taskIndex.title"
                          v-b-modal.task-status 
                          >{{
                            taskIndex.title.length > 25
                              ? taskIndex.title.slice(0, 25) + "..."
                              : taskIndex.title
                          }}</b-card-title
                        > -->
                            <b-card-title>{{
                              taskIndex.title.length > 25
                              ? taskIndex.title.slice(0, 25) + "..."
                              : taskIndex.title
                            }}</b-card-title>
                            <div class="right-status">
                              <!-- Badge Update-->
                              <!-- <b-badge
                            class="ml-auto"
                            variant="light-danger"
                            v-if="taskIndex.status == 'not-started-yet' && taskIndex.status != 'completed' && taskIndex.is_accepted != 'rejected'"
                          >
                            Not Started
                          </b-badge> -->
                              <b-badge class="ml-auto" variant="light-warning"
                                v-if="taskIndex.status == 'in-progress' && taskIndex.status != 'completed'">
                                In Progress
                              </b-badge>
                              <b-badge class="ml-auto" variant="light-primary" v-if="taskIndex.status == 'completed'">
                                Completed
                              </b-badge>
                              <b-badge class="ml-25" variant="light-info" v-if="taskIndex.is_published == 'published'">
                                Published
                              </b-badge>
                              <b-badge class="ml-25" variant="warning" v-if="taskIndex.is_accepted == null">
                                Not Accepted Yet
                              </b-badge>
                              <b-badge class="ml-25" variant="primary"
                                v-if="taskIndex.is_accepted == 'accepted' && taskIndex.status != 'completed'">
                                Accepted
                              </b-badge>
                              <b-badge class="ml-25" variant="danger" v-if="taskIndex.is_accepted == 'rejected'">
                                Rejected
                              </b-badge>
                              <!-- End Badge Update -->
                              <b-dropdown variant="link" size="sm" toggle-class="text-decoration-none p-0" no-caret>
                                <template v-slot:button-content>
                                  <feather-icon icon="MoreVerticalIcon" size="14"
                                    class="text-body align-middle m-0 text-muted" />
                                </template>

                                <b-dropdown-item class="btn-sm p-0 text-muted" @click.stop="task = taskIndex"
                                  v-b-modal.task-update>
                                  <!-- @click="editRecord(data)" -->
                                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-dark" />
                                  <span class="">Edit</span>
                                </b-dropdown-item>

                                <!-- <b-dropdown-item
                                                      class="btn-sm p-0 text-muted"
                                                      v-b-modal.task-view
                                                      @click="task = taskIndex"
                                                      >
                                                      <feather-icon
                                                          icon="EyeIcon"
                                                          size="13"
                                                          class="mr-50 text-primary"
                                                      />
                                                      <span class="">View</span>
                                                      </b-dropdown-item> -->

                                <b-dropdown-item class="btn-sm p-0" @click.stop="confirmDeleteRecordTask(taskIndex)">
                                  <feather-icon icon="TrashIcon" size="13" class="mr-50 text-dark" />
                                  <span class="">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </b-card-header>
                          <b-card-text
                            style="max-height: 10em;overflow: hidden;text-overflow: ellipsis;line-height: 1.5em;"
                            class="px-1 pt-50 pb-1 mb-0" v-html="taskIndex.description">
                          </b-card-text>
                          <!-- Photo Upload & Attach Session-->
                          <div
                            class="photo-session border-top px-1 py-50 mt-75 d-flex align-items-center justify-content-between"
                            v-if="taskIndex.files.length">
                            <div class="left-data">
                              <ul class="p-0 d-flex align-items-center m-0">
                                <li class="d-flex align-items-center justify-content-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                    fill="none">
                                    <g clip-path="url(#clip0_4408_9409)">
                                      <path
                                        d="M8.74616 4.08425L4.9545 7.87592C4.72243 8.10798 4.59206 8.42273 4.59206 8.75092C4.59206 9.0791 4.72243 9.39385 4.9545 9.62592C5.18656 9.85798 5.50131 9.98835 5.8295 9.98835C6.15769 9.98835 6.47243 9.85798 6.7045 9.62592L10.4962 5.83425C10.9603 5.37012 11.221 4.74063 11.221 4.08425C11.221 3.42787 10.9603 2.79838 10.4962 2.33425C10.032 1.87012 9.40254 1.60938 8.74616 1.60938C8.08979 1.60937 7.46029 1.87012 6.99616 2.33425L3.2045 6.12592C2.5083 6.82211 2.11719 7.76635 2.11719 8.75092C2.11719 9.73548 2.5083 10.6797 3.2045 11.3759C3.90069 12.0721 4.84493 12.4632 5.8295 12.4632C6.81406 12.4632 7.7583 12.0721 8.4545 11.3759L12.2462 7.58425"
                                        stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_4408_9409">
                                        <rect width="14" height="14" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  <label class="count mb-0">{{ taskIndex.files.length }}</label>
                                </li>

                              </ul>
                            </div>
                            <div class="right-data">
                              <div class="present-info text-danger font-weight-bolder">
                                <span><feather-icon icon="CircleIcon" /></span><span>{{ taskIndex.files.length }} File(s)
                                  Available</span>
                              </div>
                            </div>
                          </div>
                          <!-- End -->
                          <!-- <div>
                        <b-badge
                          class=""
                          variant="warning"
                          v-if="taskIndex.is_accepted == null"
                        >
                          Not Accepted Yet
                        </b-badge>
                        <b-badge
                          class=""
                          variant="primary"
                          v-if="taskIndex.is_accepted == 'accepted'"
                        >
                          Accepted
                        </b-badge>
                        <b-badge
                          class=""
                          variant="danger"
                          v-if="taskIndex.is_accepted == 'rejected'"
                        >
                          Rejected
                        </b-badge>
                      </div> -->
                          <!-- <b-row class="" v-for="file in taskIndex.files">
                        <b-col lg="8">
                          <h5>{{ file.filename }}</h5>
                        </b-col>
                        <b-col lg="4">
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-primary"
                            class="btn-icon"
                            @click="View(file.link)"
                          >
                            <feather-icon icon="EyeIcon" />
                          </b-button>
                        </b-col>
                      </b-row> -->
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="4" md="6">
                <chat-card :ChatDetails="ChatWithChild" v-if="ChatWithChild.programId" />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab>
            <template #title>
              <strong>My Secret Santa</strong>
            </template>
            <b-row v-if="onGoingProgram &&
              onGoingProgram.is_started == true &&
              onGoingProgram.isUserParticipate.secret_child != null
              ">
              <b-col lg="8" md="6" class="left-tab">
                <b-row class="ml-1 mr-1">
                  <b-tabs>
                    <b-tab active>
                      <template #title>
                        <feather-icon icon="ToolIcon" />
                        <span>Tasks</span>
                      </template>
                      <b-row class="px-1 scroll-height d-block">
                        <b-col :lg="assignedTasks.length > 1 ? '12' : '12'" md="12" v-for="taskIndex in assignedTasks"
                          class="p-0 mb-75" v-b-modal.task-status @click="task = taskIndex">
                          <b-card class="shadow-none bg-santa border-0 business-card santa-bg mx-75 my-50">
                            <b-card-header class="px-2 pt-1 pb-0">
                              <b-card-title>{{
                                taskIndex.title.length > 25
                                ? taskIndex.title.slice(0, 25) + "..."
                                : taskIndex.title
                              }}</b-card-title>
                              <div class="main-status d-flex align-items-center">
                                <div>
                                  <!-- <b-badge
                                class="ml-auto"
                                variant="light-danger"
                                v-if="taskIndex.status == 'not-started-yet' && taskIndex.status != 'completed' && taskIndex.is_accepted != 'rejected'"
                              >
                                Not Started
                              </b-badge> -->
                                  <b-badge class="ml-auto" variant="light-warning"
                                    v-if="taskIndex.status == 'in-progress' && taskIndex.status != 'completed'">
                                    In Progress
                                  </b-badge>
                                  <b-badge class="ml-auto" variant="light-primary" v-if="taskIndex.status == 'completed'">
                                    Completed
                                  </b-badge>
                                  <b-badge class="ml-25" variant="light-info"
                                    v-if="taskIndex.is_published == 'published'">
                                    Published
                                  </b-badge>
                                </div>
                                <div>
                                  <b-badge class="ml-25" variant="warning" v-if="taskIndex.is_accepted == null">
                                    Not Accepted Yet
                                  </b-badge>
                                  <b-badge class="ml-25" variant="primary"
                                    v-if="taskIndex.is_accepted == 'accepted' && taskIndex.status != 'completed'">
                                    Accepted
                                  </b-badge>
                                  <b-badge class="ml-25" variant="danger" v-if="taskIndex.is_accepted == 'rejected'">
                                    Rejected
                                  </b-badge>
                                </div>
                              </div>
                            </b-card-header>
                            <b-card-text class="px-2 pt-50 pb-1 mb-0" v-html="taskIndex.description">
                            </b-card-text>
                            <hr class="m-0" />
                            <b-row class="px-2 pt-1 d-flex bg-transparent task-status justify-content-between">
                              <b-col class="accept-reject pl-0 d-flex align-items-center" sm="12" md="8">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="light"
                                  class="btn-icon accept-btn mr-1 d-flex align-items-center"
                                  @click.stop="respond(taskIndex.hashid, 'accepted')"
                                  v-if="taskIndex.is_accepted != 'accepted'" pill>
                                  <feather-icon icon="CheckIcon" class="mr-25" /> Accept Task
                                  <!-- <feather-icon icon="ThumbsUpIcon" /> -->
                                </b-button>
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="light"
                                  class="btn-icon ml-0 reject-btn d-flex align-items-center"
                                  @click.stop="respond(taskIndex.hashid, 'rejected')"
                                  v-if="taskIndex.is_accepted != 'rejected' && taskIndex.status != 'completed'" pill>
                                  <feather-icon icon="XIcon" class="mr-25" /> Reject Task
                                  <!-- <feather-icon icon="ThumbsDownIcon" /> -->
                                </b-button>
                              </b-col>
                              <b-col class="d-flex align-items-center text-right justify-content-end pr-0" sm="12" md="4">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent"
                                  class="btn-icon ml-1 bg-transparent text-secondary p-0"
                                  @click.stop="update_task = taskIndex" v-if="taskIndex.is_accepted == 'accepted'"
                                  v-b-modal.task-file-upload v-b-tooltip.hover.v-dark
                                  v-b-tooltip.hover.top="'Update Photos'">
                                  <feather-icon icon="PaperclipIcon" width="20px" height="20px" />
                                </b-button>
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="transparent"
                                  class="btn-icon ml-1 bg-transparent text-secondary p-0"
                                  @click.stop="publish(taskIndex, 'published')"
                                  v-if="taskIndex.is_published != 'published'" v-b-tooltip.hover.v-dark
                                  v-b-tooltip.hover.top="taskIndex.status == 'completed'
                                      ? 'Publish Task'
                                      : 'Complete Task to Publish'
                                    ">
                                  <feather-icon icon="GlobeIcon" width="20px" height="20px" />
                                </b-button>
                                <!-- <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="btn-icon ml-1"
                                @click="publish(taskIndex, 'un-published')"
                                v-if="taskIndex.is_published == 'published'"
                                v-b-tooltip.hover.v-danger
                                v-b-tooltip.hover.right="'Un-Publish Task'"
                              >
                                <feather-icon icon="SlashIcon" />
                              </b-button> -->
                              </b-col>

                              <b-col class="accept-reject mt-25 pl-0 d-flex align-items-center" sm="12" md="8">
                                <div class="attched-file">
                                  <ul class="p-0 m-0">
                                    <li v-for="file in taskIndex.files" @click.stop="openFile(file)" class="py-50">
                                      <p class="m-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                          fill="none">
                                          <g>
                                            <path
                                              d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                              stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                                          </g>
                                          <defs>
                                            <clipPath>
                                              <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                        <span>{{ file.filename }}</span>
                                        <span class="close d-flex align-items-center justify-content-center">
                                          <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                            @click.stop="Delete(file, taskIndex.hashid)" />
                                        </span>
                                      </p>
                                    </li>

                                  </ul>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row class="m-2 bg-transparent">
                              <!-- <div class="attched-file mt-1">
                                <ul class="p-0">
                                  <li v-for="file in taskIndex.files" @click="openFile(file)">
                                    <p>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <g>
                                          <path
                                            d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                            stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                          <clipPath>
                                            <rect width="16" height="16" fill="white" />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      <span>{{ file.filename }}</span>
                                      <span class="close d-flex align-items-center justify-content-center">
                                        <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                        @click.stop="Delete(file, taskIndex.hashid)" />
                                      </span>
                                    </p>
                                  </li>
                                  
                                </ul>
                              </div> -->
                              <!-- <h5>{{ file.filename }}</h5>
                             
                                <b-button
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="flat-primary"
                                  class="btn-icon"
                                  @click="View(file.link)"
                                >
                                  <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="flat-danger"
                                  class="btn-icon"
                                  @click="Delete(file, taskIndex.hashid)"
                                >
                                  <feather-icon icon="XIcon" />
                                </b-button> -->
                            </b-row>
                          </b-card>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <feather-icon icon="HeartIcon" />
                        <span>Wishlist</span>
                      </template>

                      <b-card no-body class="business-card santa-bg m-2 shadow-none">
                        <b-card-header class="pb-1">
                          <b-card-title>Build Wishlist <span v-if="onGoingProgram.gift_price_limit" class="small-note">(
                              Remember, the items can’t exceed ${{ onGoingProgram.gift_price_limit }} )</span>
                          </b-card-title>
                        </b-card-header>

                        <b-card-body>
                          <b-row v-for="(wish, k) in wishlist" :key="k" class="bg-transparent">
                            <b-col md="12" class="p-0 add-wishlist">
                              <b-form-group>
                                <label class="" for="name">Wish #{{ k + 1 }}</label>
                                <b-form-input id="wishs" v-model="wishlist[k]" name="wishs" placeholder="Enter Wish" />
                              </b-form-group>
                              <b-form-group class="w-100 text-right mb-0">
                                <b-button v-if="k > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="outline-secondary" class="btn-icon rounded-circle ml-2" @click="removeWish(k)">
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row class="bg-transparent">
                            <b-col md="12" class="p-0 add-morewishlist">
                              <b-form-group>
                                <b-button
                                  class="add-options d-flex align-items-center justify-content-end w-100 p-0 mt-0 bg-transparent border-0"
                                  @click="addWish">
                                  <feather-icon icon="PlusIcon" size="15" /> <label for="name" class="mb-0 ml-50">Add More
                                    Option</label>
                                </b-button>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <!-- button -->
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" block
                            @click="enrollMe" pill class="w-auto">
                            Update Wishlist
                          </b-button>
                        </b-card-body>
                      </b-card>
                    </b-tab>
                    <b-tab v-if="onGoingProgram.is_postal == 'true'">
                      <template #title>
                        <feather-icon icon="MapPinIcon" />
                        <span>Delivery Address</span>
                      </template>
                      <b-card no-body class="business-card santa-bg m-2 shadow-none delivery-addr">
                        <b-card-body>
                          <b-row class="bg-transparent">
                            <b-col md="12">
                              <h4 class="mb-1">Your Delivery Address</h4>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <b-form-group label="Address Line 1" label-for="address-line-1" class="mb-2">
                                <b-form-input id="address-line-1" v-model="enrollment.delivery_address.add_line_1
                                  " type="text" placeholder="Address Line 1" />
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <b-form-group label="Address Line 2" label-for="address-line-2" class="mb-2">
                                <b-form-input id="address-line-2" v-model="enrollment.delivery_address.add_line_2
                                  " type="text" placeholder="Address Line 2" />
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <b-form-group label="Postcode" label-for="postcode" class="mb-2">
                                <b-form-input id="postcode" v-model="enrollment.delivery_address.post_code
                                  " type="text" placeholder="Postcode" />
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <b-form-group label="City" label-for="city" class="mb-2">
                                <b-form-input id="city" v-model="enrollment.delivery_address.city" type="text"
                                  placeholder="City" />
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <b-form-group label="State" label-for="state" class="mb-2">
                                <b-form-input id="state" v-model="enrollment.delivery_address.state" type="text"
                                  placeholder="State" />
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <b-form-group label="Country" label-for="country" class="mb-2">
                                <b-form-input id="country" v-model="enrollment.delivery_address.country" type="text"
                                  placeholder="Country" />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" block pill
                            class="text-danger w-auto font-weight-bolder mt-2" @click="enrollMe">
                            Update Delivery Address
                          </b-button>
                        </b-card-body>
                      </b-card>
                    </b-tab>
                    <b-tab v-if="new Date().toISOString().split('T')[0] >=
                      new Date('2022-12-03').toISOString().split('T')[0]
                      ">
                      <template #title>
                        <feather-icon icon="HelpCircleIcon" />
                        <span>Guesses</span>
                      </template>
                      <b-card no-body class="business-card santa-bg m-2 shadow-none">
                        <b-card-header class="pb-1">
                          <b-card-title>Guess Your Secret Santa</b-card-title>
                          <!-- <feather-icon
                            icon="MoreVerticalIcon"
                            size="18"
                            class="cursor-pointer"
                          /> -->
                        </b-card-header>

                        <b-card-body>
                          <b-card-text>Based on the tasks and gift that is given to you,
                            can you guess your secret santa ? It’s a holiday
                            classic where the guessing is as much a part of the
                            fun as receiving the gift.</b-card-text>
                          <h5 class="mb-25">
                            Your Got 5 Guesses, Best of Luck
                          </h5>
                          <div class="business-items mb-2">
                            <b-list-group>
                              <b-list-group-item v-for="(guess, key) in guesses" :key="key" class="bg-transparent px-0">
                                <v-select v-model="guesses[key]" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  " :options="onGoingProgram.participantsOpions" :clearable="false" :searchable="false"
                                  :input-id="'guess-' + key" :reduce="(val) => val.code" :placeholder="'Guess #' +
                                    key +
                                    ''
                                    " />
                              </b-list-group-item>
                            </b-list-group>
                          </div>
                          <!-- button -->
                          <div class="d-flex align-items-center btn-gap justify-content-center">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" block pill
                              @click="UpdateGuesses" class="text-danger font-weight-bolder">
                              Update Your Guesses
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" block
                              @click="publishGuesses" v-if="new Date().toISOString().split('T')[0] >=
                                new Date('2022-12-03').toISOString().split('T')[0]
                                " pill class="font-weight-bolder mt-0">
                              Publish My Guesses
                            </b-button>
                          </div>
                        </b-card-body>
                      </b-card>
                    </b-tab>
                    <b-tab v-if="new Date().toISOString().split('T')[0] >=
                      new Date('2022-12-05').toISOString().split('T')[0]
                      ">
                      <template #title>
                        <feather-icon icon="GiftIcon" />
                        <span>My Gifts</span>
                      </template>
                      <b-card no-body class="business-card santa-bg m-2 shadow-none">
                        <b-card-header class="pb-1">
                          <b-card-title>My gifts from my secret santa</b-card-title>
                          <!-- <feather-icon
                            icon="MoreVerticalIcon"
                            size="18"
                            class="cursor-pointer"
                          /> -->
                        </b-card-header>

                        <b-card-body>
                          <b-card-text>
                            You can upload pictures,videos of the gifts that you
                            got from your secret santa and write a personal
                            message and christmas wish to your secret santa. and
                            also publish it if you feel like it.
                          </b-card-text>
                          <div class="business-items mb-2">
                            <b-form-group class="mb-2">
                              <b-form-textarea id="message" v-model="mygift.message" name="message"
                                placeholder="Message & Christmas Wish" rows="4" />
                            </b-form-group>
                            <b-form-group label="Attachments" label-for="giftfiles">
                              <file-pond ref="giftfilesPond" name="giftfiles[]" max-file-size="50MB" allow-paste="false"
                                max-files="5" allow-multiple="true" v-bind:files="mygift.giftFiles"
                                image-preview-height="120px" accepted-file-types="image/*,video/*,application/pdf"
                                file-validate-type-label-expected-types="Expects a PDF, Images, Video file" />
                              <small class="text-secondary flex items-center notes">
                                File size should be less than 50MB and allowed
                                formats are ( .mp4,.pdf, image)</small>
                            </b-form-group>
                            <b-row class="m-2">
                              <div class="attched-file">
                                <ul class="p-0 m-0">
                                  <li v-for="file in mygift.Files" @click="View(file.link)" class="py-50">
                                    <p class="m-0">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <g>
                                          <path
                                            d="M9.99785 4.66632L5.66452 8.99965C5.3993 9.26487 5.2503 9.62458 5.2503 9.99965C5.2503 10.3747 5.3993 10.7344 5.66452 10.9997C5.92973 11.2649 6.28944 11.4139 6.66452 11.4139C7.03959 11.4139 7.3993 11.2649 7.66452 10.9997L11.9978 6.66632C12.5283 6.13588 12.8263 5.41646 12.8263 4.66632C12.8263 3.91617 12.5283 3.19675 11.9978 2.66632C11.4674 2.13588 10.748 1.83789 9.99785 1.83789C9.2477 1.83789 8.52828 2.13588 7.99785 2.66632L3.66452 6.99965C2.86887 7.7953 2.42187 8.87443 2.42188 9.99965C2.42188 11.1249 2.86887 12.204 3.66452 12.9997C4.46017 13.7953 5.5393 14.2423 6.66452 14.2423C7.78973 14.2423 8.86887 13.7953 9.66452 12.9997L13.9978 8.66632"
                                            stroke="#636363" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                          <clipPath>
                                            <rect width="16" height="16" fill="white" />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      <span>{{ file.filename }}</span>
                                      <span class="close d-flex align-items-center justify-content-center">
                                        <feather-icon class="cursor-pointer close-btn" icon="XIcon" size="10"
                                          @click.stop="DeleteGiftFile(file, onGoingProgram.hashid)" />
                                      </span>
                                    </p>
                                  </li>

                                </ul>
                              </div>
                              <!-- <b-col lg="8">
                                <h5>{{ file.filename }}</h5>
                              </b-col>
                              <b-col lg="4">
                                <b-button
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="flat-primary"
                                  class="btn-icon"
                                  @click="View(file.link)"
                                >
                                  <feather-icon icon="EyeIcon" />
                                </b-button>
                                <b-button
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="flat-danger"
                                  class="btn-icon"
                                  @click="
                                    DeleteGiftFile(file, onGoingProgram.hashid)
                                  "
                                >
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </b-col> -->
                            </b-row>
                          </div>
                          <!-- button -->
                          <div class="d-flex align-items-center justify-content-center btn-gap mb-2">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" block
                              @click="UpdateGifts" pill class="text-danger font-weight-bolder">
                              Update Your Gifts
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" block
                              class="mt-0 font-weight-bolder" pill @click="PublishGifts" v-if="mygift.publish_status != 'published' &&
                                new Date().toISOString().split('T')[0] >=
                                new Date('2022-12-03')
                                  .toISOString()
                                  .split('T')[0]
                                ">
                              Publish My Gifts
                            </b-button>
                          </div>
                        </b-card-body>
                      </b-card>
                    </b-tab>
                  </b-tabs>
                </b-row>
              </b-col>
              <b-col lg="4" md="6">
                <chat-card :ChatDetails="ChatWithSanta" class="" v-if="ChatWithSanta.programId" />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <PostEdit :item-id.sync="postId" :sidebar-edit-active.sync="sidebarEditActive"
      :employeeListOptions.sync="employeeListOptions" :customFields.sync="customFields" />
  </div>
</template>

<script>
import store from "@/store";
import $ from "jquery";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Countdown from "@/views/feed/polls/CountDown.vue";
import ChildProfile from "./ChildProfile.vue";
import ChatCard from "@/views/card/ChatCard.vue";
import PostEdit from "@/views/feed/PostEdit.vue";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
  BBadge,
  BProgress,
  BProgressBar,
  BSidebar,
  VBModal,
  BTable,
  BFormTags,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import { onUnmounted, watch, ref } from "@vue/composition-api";
import secretSantaProgramsStoreModule from "./secretSantaProgramsStoreModule";
import useSecretSantaProgramList from "./list";
import bomb from "@/libs/bomb/bomb";
import QuillInput from "@/components/QuillInput.vue";
import { bus } from "@/main.js";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VBTooltip,
    BBadge,
    BProgress,
    BFormTags,
    BProgressBar,
    BSidebar,
    VBModal,
    vSelect,
    flatPickr,
    BTable,
    Countdown,
    ChildProfile,
    QuillInput,
    ChatCard,
    FilePond,
    PostEdit,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-secret_santa_programs";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedSecretSantaPrograms = ref([]);
    const secret_santa_programsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        secretSantaProgramsStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const {
      fetchSecretSantaProgramList,
      tableColumns,
      perPage,
      currentPage,
      totalSecretSantaProgramList,
      secret_santa_programsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      resolveUserStatusVariant,
      SecretSantaProgramStatusOption,
      statusFilter,
      categoryFilter,
      SecretSantaProgramCategoriesOption,
    } = useSecretSantaProgramList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedSecretSantaPrograms.value = [];
      if (val) {
        secret_santa_programsMetaData.value.forEach((santa) => {
          selectedSecretSantaPrograms.value.push(santa.hashid);
        });
      }
    });

    watch(selectedSecretSantaPrograms, (val) => {
      secret_santa_programsDeleteCount.value = val.length;
    });

    return {
      fetchSecretSantaProgramList,
      tableColumns,
      perPage,
      currentPage,
      totalSecretSantaProgramList,
      secret_santa_programsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      resolveUserStatusVariant,
      SecretSantaProgramStatusOption,
      statusFilter,
      categoryFilter,
      SecretSantaProgramCategoriesOption,

      isDeleteMultipleCandChecked,
      selectedSecretSantaPrograms,
      secret_santa_programsDeleteCount,
    };
  },
  data() {
    return {
      is_ready:false,
      customFields: {},
      fullScreen: false,
      businessItems: [
        {
          option: "Option #1",
          money: "+$39",
          badgeColor: "light-success",
          checked: false,
        },
        {
          option: "Option #2",
          money: "+85",
          badgeColor: "light-primary",
          checked: true,
        },
        {
          option: "Option #3",
          money: "+$199",
          badgeColor: "light-success",
          checked: false,
        },
        {
          option: "Option #4",
          money: "+$459",
          badgeColor: "light-success",
          checked: false,
        },
      ],
      guesses: ["", "", "", "", ""],
      mygift: {
        message: "",
        giftFiles: [],
        Files: [],
      },
      userData: this.$cookies.get("userData"),
      program: {
        start_date: "",
        sign_up_dead_line: "",
        year: "",
        gift_price_limit: 0,
        is_postal: "false",
        invitation_type: "invite_all",
        emails: [],
      },
      closed: false,
      muted: localStorage.getItem("muted") == true ? true : false,
      selectedProgram: {},
      invitation_options: [
        { label: "Invite All Members", code: "invite_all" },
        { label: "Invite By Email", code: "invite_by_email" },
        //{ label: "Invite By Link", code: "invite_by_link" },
      ],
      onGoingProgram: null,
      wishlist: [""],
      enrollment: {
        delivery_address: {
          add_line_1: "",
          add_line_2: "",
          post_code: "",
          city: "",
          state: "",
          country: "",
        },
      },
      tasks: [],
      assignedTasks: [],
      update_task: {},
      myFiles: [],
      task: {
        title: "",
        description: "",
        hashid: "",
        secret_santa_program_id: "",
      },
      items: [
        {
          text: "Secret Santa Program",
        },
        {
          text: "My Little Elf",
        },
        {
          text: "Tasks",
        },
      ],
      myItems: [
        {
          text: "Secret Santa Program",
        },
        {
          text: "My Secret Santa",
        },
        {
          text: "Tasks",
        },
      ],
      ChatWithChild: {
        name: "",
        avatar: "",
        sender_avatar: require("@/assets/images/secret-santa/santa-claus-avatar.svg"),
        contactId: "",
        senderId: 11,
        conversation: [],
        type: "secret-santa",
        programId: "",
      },
      ChatWithSanta: {
        name: "Secret Santa",
        avatar: require("@/assets/images/secret-santa/santa-claus-avatar.svg"),
        sender_avatar: "",
        contactId: 11,
        senderId: "",
        conversation: [],
        type: "secret-santa",
        programId: "",
      },
      sidebarEditActive: true,
      postId: "",
      post: {},
      employeeListOptions: {},
    };
  },
  watch: {
    sidebarActive: function (newVal, oldVal) {
      this.refetchData();
    },
  },
  created() {
    if (!this.$can("secret_santa_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
    this.getOnGoingProgram();
    this.getCustomFields();
    bus.$on("getOnGoingProgram", () => {
      console.log("getOnGoingProgram");
      this.getOnGoingProgram();
    });
    setInterval(function () {
      bomb.snow();
    }, 4500);
  },
  mounted() {
    if (localStorage.getItem("muted")) {
      $("#audio").prop("muted", true);
      this.muted = true;
    }
  },
  methods: {
    revealNow(){
      this.is_ready = true; 
      bomb.continuous();
    },
    closeReveal(){
      this.is_ready = false;
      this.$refs["modal-reveal"].hide();
    },
    refreshAll() {
      this.getOnGoingProgram();
    },
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          this.customFields = res.data;
        })
        .catch((error) => {
          if (error.response.status != 403) {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Something went wrong",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: error.toString(),
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                icon: 'BellIcon',
                variant: 'danger',
                text:  error.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    UpdateGuesses() {
      var guessList = [];
      for (var i = 0; i < this.guesses.length; i++) {
        if (this.guesses[i] != "") {
          if (!guessList.includes(this.guesses[i])) {
            guessList.push(this.guesses[i]);
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to Update Guesses",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: "All Guesses must be unique, please check the list.",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to Update Guesses',
                icon: 'BellIcon',
                variant: 'danger',
                text: "All Guesses must be unique, please check the list.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            return 0;
          }
        }
      }
      axios
        .post("/update-santa-guesses", {
          guesses: guessList,
          ssp_id: this.onGoingProgram.hashid,
        })
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Your Secret Santa Guesses Updated.",
            //     icon: "EditIcon",
            //     variant: "primary",
            //     text: "Result will be revealed on 25 Dec.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Your Secret Santa Guesses Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Result will be revealed on 25 Dec.'
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to Update Guesses",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to Update Guesses',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    UpdateGifts() {
      if (!this.mygift.message || this.mygift.message == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Message is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Message is Required`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        return 0;
      }
      const formData = new FormData();
      const files = this.$refs.giftfilesPond.getFiles();
      const keys = Object.keys(this.$refs.giftfilesPond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }
      formData.append("program_id", this.onGoingProgram.hashid);
      formData.append("message", this.mygift.message);
      if (Array.from(formData.keys()).length == 2) {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Please Upload atleast one file",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Please Upload atleast one file`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else {
        axios.post("/update-santa-gifts", formData).then((response) => {
          this.mygift.hashid = response.data.data.hashid;
          console.log(response);
          if (response.data.success) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Your Secret Santa Gift and Message Updated.",
            //     icon: "EditIcon",
            //     variant: "primary",
            //   },
            // });
            this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Your Secret Santa Gift and Message Updated.',
                    icon: 'EditIcon',
                    variant: 'success',
                    hideClose: true,
                  },
                }, {
                  timeout: 2000, 
                  position: 'bottom-center',
                  toastClassName:"successful-info",
                  hideProgressBar : true,
                }); 
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to Update Gift and Message",
            //     icon: "EditIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to Update Gift and Message`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })

          }
        });
      }
    },
    PublishGifts() {
      const self = this;

      let title = "✨These are my gifts from my secret santa 🎅";
      let body = "";
      body += `<p>` + this.mygift.message + `</p><p>&nbsp;😍😍</p><p>It has been so much playing with you @Secret Santa's Name! 💚</p><p><br></p><p>I will cherish this gift for a long while to come.</p><p><br></p><p>Wishing you, and yours A Merry Christmas &amp;&nbsp;A Happy New Year </p><p>See you again next year 🥂</p>`;
      let data = {};
      data.url = "posts";

      data.params = {
        channel_type: "secret_santa_post",
        post_anonymously: false,
        feedback_type: "",
        title: title,
        body: body,
        scheduled_time: "",
        is_scheduled: false,
        is_draft: true,
        is_published: false,
        secret_santa_gift_hashid: this.mygift.hashid,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          self.postId = res.data.data.hashid;
          self.post = res.data.data;
          this.$root.$emit("bv::toggle::collapse", "edit-sidebar");
        })
        .catch((error) => {
          this.isBusy = false;
          console.error(error);
        });
    },
    getOnGoingProgram() {
      axios.get("/on-going-secret-santa").then((response) => {
        if (response.data.success) {
          this.onGoingProgram = response.data.data;
          if (this.onGoingProgram.isUserParticipate.participant) {
            this.enrollment.delivery_address = this.onGoingProgram
              .isUserParticipate.participant.delivery_address.add_line_1
              ? this.onGoingProgram.isUserParticipate.participant
                .delivery_address
              : this.enrollment.delivery_address;
            this.wishlist = this.onGoingProgram.isUserParticipate.participant
              .wishlist.length
              ? this.onGoingProgram.isUserParticipate.participant.wishlist
              : ["", ""];
            this.guesses =
              this.onGoingProgram.isUserParticipate.participant.guesses;
            while (!this.guesses || this.guesses.length < 5) {
              if (!this.guesses) {
                this.guesses = [""];
              }
              this.guesses.push("");
            }
          }
          this.ChatWithSanta.programId = this.onGoingProgram.hashid;
          this.ChatWithSanta.conversation = this.onGoingProgram.santa_chat;
          this.ChatWithSanta.sender_avatar = this.userData.avatar;
          this.ChatWithSanta.senderId = this.userData.hashid;

          this.mygift = this.onGoingProgram.myGifts;
          this.ChatWithChild.programId = this.onGoingProgram.hashid;
          this.ChatWithChild.conversation = this.onGoingProgram.child_chat;
          if (this.onGoingProgram.isUserParticipate.secret_child) {
            this.ChatWithChild.name =
              this.onGoingProgram.isUserParticipate.secret_child.name;
            this.ChatWithChild.avatar =
              this.onGoingProgram.isUserParticipate.secret_child.avatar;
            this.ChatWithChild.contactId =
              this.onGoingProgram.isUserParticipate.secret_child.hashid;
          }

          if (this.onGoingProgram && this.onGoingProgram.is_started) {
            this.tasksList();
            this.assignedTasksList();
          }
        } else {
          this.onGoingProgram = null;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to Get On Going Program",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
        }
      });
    },
    resetModal() {
      this.program = {
        start_date: "",
        sign_up_dead_line: "",
        year: "",
        gift_price_limit: "",
        is_postal: "false",
        invitation_type: "invite_all",
        emails: [],
      };
    },
    resetFileModal() {
      this.update_task = {};
    },
    muteAudio() {
      $("#audio").prop("muted", true);
      this.muted = true;
      localStorage.setItem("muted", true);
    },
    unmuteAudio() {
      $("#audio").prop("muted", false);
      localStorage.setItem("muted", false);
      this.muted = false;
    },
    View(file) {
      window.open(file, "_blank");
    },
    publish(task, publish_state) {
      const self = this;

      if (task.status == "completed") {
        let data = {};
        data.url = "posts";
        var tasks_contents = [
          {
            channel_type: "secret_santa_post",
            post_anonymously: false,
            feedback_type: "",
            title: "New Task - Done & Dusted💃🎉🎊😂",
            body:
              "<p><em>I was tasked with <b>'" + task.title + "'</b>, and boy was that fun!</em></p><p><em>Knowing I'm so much closer to finding you, is so much more fun though&nbsp;</em>😂 <em>Is everyone else also enjoying the game? Do share!</em></p>",
            scheduled_time: "",
            is_scheduled: false,
            is_draft: true,
            is_published: false,
            files: task.files,
            task_hashid: task.hashid,
          },
          {
            channel_type: "secret_santa_post",
            post_anonymously: false,
            feedback_type: "",
            title: "Secret Santa 0, " + this.userData.name + " 1 😈",
            body:
              "<p>Task give <b>" + task.title + "</b></p><p>Task Status - Completed.</p><p>Keep your eye peeled, Santa! I'm this much closer to figuring out who you are 😛</p><p>I bet I can find you before anyone else finds their Santa.</p><p>What's everyone been doing anyway? Do share and let the rest of use know 🌚</p>",
            scheduled_time: "",
            is_scheduled: false,
            is_draft: true,
            is_published: false,
            files: task.files,
            task_hashid: task.hashid,
          }
        ];

        const randomIndex = Math.floor(Math.random() * tasks_contents.length);

        data.params = tasks_contents[randomIndex];

        this.isBusy = true;
        this.$store
          .dispatch("app/store", data)
          .then((res) => {
            this.isBusy = false;
            self.postId = res.data.data.hashid;
            self.post = res.data.data;

            this.$root.$emit("bv::toggle::collapse", "edit-sidebar");
          })
          .catch((error) => {
            this.isBusy = false;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Post Adding Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: error,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Post Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                  
          });
        this.getOnGoingProgram();
      } else {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Unable to publish task",
        //     icon: "EditIcon",
        //     variant: "danger",
        //     text: "Task must be updated as completed by Secret Santa.",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to publish task',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Task must be updated as completed by Secret Santa.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
      }
    },
    publishGuesses() {
      const self = this;
      const map1 = self.guesses;

      let participants = self.onGoingProgram.participantsOpions.map(function (
        item
      ) {
        return self.guesses.includes(item.code) ? item : false;
      });

      participants = participants.map((x) => Object.values(x));

      let title = "❗️❗️ Attention, Attention ❗️❗️";
      let body = "";
      body += `<p>🥁 Drum Roll Please 🥁</p><p>🤔 After serious consideration</p><p>😶 much contemplation,</p><p>🤥 and little bit of confusion,</p><p>😳 I've come to the conclusion</p><p>🎅My Secret Santa is most likely:</p></p><ol>`;

      participants.map(function (currentValue, index, arr) {
        if (currentValue[0]) {
          body +=
            `<li><span class="mention" data-index="0" data-denotation-char="@" data-value="` +
            currentValue[0].split(" ").slice(0, 1).join(" ") +
            `" data-id="` +
            currentValue[1] +
            `"
              data-hover="` +
            currentValue[0] +
            `">
              <span contenteditable="false">
                <span class="ql-mention-denotation-char">@</span>
                ` +
            currentValue[0].split(" ").slice(0, 1).join(" ") +
            `
              </span>
            </span></li>`;
        }

      });

      body += `</ol> <p>Taking bets on whether I am right.</p><p>Minimum betting price is 1k.</p><p>(This IS NOT a joke, obviously)</p><p>Do DM 😆🤣</p>`;

      let data = {};
      data.url = "posts";

      data.params = {
        channel_type: "secret_santa_post",
        post_anonymously: false,
        feedback_type: "",
        title: title,
        body: body,
        scheduled_time: "",
        is_scheduled: false,
        is_draft: true,
        is_published: false,
        secret_santa_participant_hashid: this.onGoingProgram.hashid,
      };

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          self.postId = res.data.data.hashid;
          self.post = res.data.data;

          this.$root.$emit("bv::toggle::collapse", "edit-sidebar");
          this.getOnGoingProgram();
        })
        .catch((error) => {
          this.isBusy = false;
          console.error(error);
        });
      this.getOnGoingProgram();
    },
    Delete(file, taskid) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete  task file ${file.filename}.`,

        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-danger yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            file: file.filename,
          };
          axios.post("task-delete-file/" + taskid, data).then((response) => {
            if (response.data.success) {
              this.getOnGoingProgram();
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Task File Deleted successfully",
              //     icon: "EditIcon",
              //     variant: "primary",
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Task File Deleted successfully',
                icon: 'CheckIcon',
                variant: 'success',
                hideClose: true,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"successful-info",
              hideProgressBar : true,
            }); 
            } else {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Unable to delete task file",
              //     icon: "EditIcon",
              //     variant: "danger",
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to delete task file`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
            }
          });
        }
      });
    },
    DeleteGiftFile(file, program_id) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete a Gift file ${file.filename}.`,

        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-danger yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            file: file.filename,
            ssp_id: program_id,
          };
          axios.post("gift-delete-file", data).then((response) => {
            if (response.data.success) {
              this.getOnGoingProgram();
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Gift File Deleted successfully",
              //     icon: "EditIcon",
              //     variant: "primary",
              //   },
              // });

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Gift File Deleted successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                  hideClose: true,
                },
              }, {
                timeout: 2000, 
                position: 'bottom-center',
                toastClassName:"successful-info",
                hideProgressBar : true,
              }); 
            } else {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Unable to delete Gift file",
              //     icon: "EditIcon",
              //     variant: "danger",
              //   },
              // });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to delete Gift file`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
            }
          });
        }
      });
    },
    uploadFiles(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      const formData = new FormData();
      const files = this.$refs.pond.getFiles();
      const keys = Object.keys(this.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }
      formData.append("task_id", this.update_task.hashid);
      if (Array.from(formData.keys()).length == 1) {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Please Upload atleast one file",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Please Upload atleast one file`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else {
        axios.post("/task-file-upload", formData).then((response) => {
          if (response.data.success) {
            this.getOnGoingProgram();
            this.$refs["task-file-upload"].hide();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Task file uploaded successfully",
            //     icon: "EditIcon",
            //     variant: "primary",
            //   },
            // });
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Task file uploaded successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                  hideClose: true,
                },
              }, {
                timeout: 2000, 
                position: 'bottom-center',
                toastClassName:"successful-info",
                hideProgressBar : true,
              }); 
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Task file uploade failed",
            //     icon: "EditIcon",
            //     variant: "primary",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Task file uploade failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      }
    },
    resetEnroll() {
      this.enrollment = {
        delivery_address: {
          add_line_1: "",
          add_line_2: "",
          post_code: "",
          city: "",
          state: "",
          country: "",
        },
        wishlist: [""],
      };
      this.wishlist = [""];
    },
    formSubmitted(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.program.invitation_type == 'invite_by_email') {
        if (this.program.emails.length == 0) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Enter emails",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: "Please enter valid emails to invite"
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enter emails',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter valid emails to invite",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                  
          return;
        }
        for (let index = 0; index < this.program.emails.length; index++) {
          var email = this.program.emails[index];
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          var isValid = emailRegex.test(email);
          if (!isValid) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Enter a valid email",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: email + " is not a valid email"
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enter a valid email',
                icon: 'BellIcon',
                variant: 'danger',
                text: email + " is not a valid email",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            return;
          }
        };
      }
      if (this.program.sign_up_dead_line == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Sign Up Deadline Date is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Sign Up Deadline Date is Required`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else if (this.program.gift_price_limit == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Gift Price Limit is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Gift Price Limit is Required`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else {
        axios
          .post("/secret-santa-program", this.program)
          .then((response) => {
            if (response.status == "201") {
              this.refetchData();
              this.getOnGoingProgram();
              this.$refs["create_ss"].hide();
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Secret Santa Program created successfully",
              //     icon: "EditIcon",
              //     variant: "primary",
              //   },
              // });
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Secret Santa Program created successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                  hideClose: true,
                },
              }, {
                timeout: 2000, 
                position: 'bottom-center',
                toastClassName:"successful-info",
                hideProgressBar : true,
              }); 
            }
          })
          .catch((error) => {
            for (var err in error.response.data.errors) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Error in " + err,
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: error.response.data.errors[err][0],
              //   },
              // });

              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            }
          });
      }
    },
    formUpdated(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.selectedProgram.invitation_type == 'invite_by_email') {
        if (this.selectedProgram.emails.length == 0) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Enter emails",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: "Please enter valid emails to invite"
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enter emails',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter valid emails to invite",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          return;
        }
        for (let index = 0; index < this.selectedProgram.emails.length; index++) {
          var email = this.selectedProgram.emails[index];
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          var isValid = emailRegex.test(email);
          if (!isValid) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Enter a valid email",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: email + " is not a valid email"
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Enter a valid email",
                icon: 'BellIcon',
                variant: 'danger',
                text: email + " is not a valid email",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            return;
          }
        };
      }
      if (this.selectedProgram.sign_up_dead_line == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Sign Up Deadline Date is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Sign Up Deadline Date is Required`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else if (this.selectedProgram.gift_price_limit == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Gift Price Limit is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Gift Price Limit is Required`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else {
        axios
          .patch(
            "/secret-santa-program/" + this.selectedProgram.hashid,
            this.selectedProgram
          )
          .then((response) => {
            if (response.status == "202") {
              this.refetchData();
              this.getOnGoingProgram();
              this.$refs["edit_ss"].hide();
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "",
              //     icon: "EditIcon",Secret Santa Program updated successfully
              //     variant: "primary",
              //   },
              // });
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Secret Santa Program updated successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                  hideClose: true,
                },
              }, {
                timeout: 2000, 
                position: 'bottom-center',
                toastClassName:"successful-info",
                hideProgressBar : true,
              }); 
            }
          })
          .catch((error) => {
            for (var err in error.response.data.errors) {
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: "Error in " + err,
              //     icon: "EditIcon",
              //     variant: "danger",
              //     text: error.response.data.errors[err][0],
              //   },
              // });

              this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            }
          });
      }
    },
    editRecord(data) {
      this.selectedProgram = {
        hashid: data.item.hashid,
        start_date: data.item.start_date,
        sign_up_dead_line: data.item.sign_up_dead_line,
        year: data.item.year,
        gift_price_limit: data.item.gift_price_limit,
        is_postal: data.item.is_postal,
        invitation_type: data.item.invitation_type,
        emails: data.item.emails,
      };
      this.$refs["edit_ss"].show();
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-secret_santa_programs/removeSSP", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Secret Santa Program Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Secret Santa Program Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Secret Santa Program Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Secret Santa Program Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Secret Santa Program Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Secret Santa Program Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
          this.getOnGoingProgram();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Secret Santa Program Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Secret Santa Program Deletion failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },

    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete secret santa program.`,
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-danger yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedSecretSantaPrograms.length} Secrect Santa Programs, This cannot be undone.`,
        // icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-danger yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-secret_santa_programs/removeMultipleSSP",
              this.selectedSecretSantaPrograms
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedSecretSantaPrograms = [];
              if (res.data.success) {
                this.refetchData();
                this.getOnGoingProgram();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully.",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Secret Santa Program Deletion failed.",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Secret Santa Program Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    viewRecord(data) {
      console.log(data);
    },
    addWish() {
      this.wishlist.push("");
    },
    removeWish(index) {
      this.wishlist.splice(index, 1);
    },
    enrollMe() {
      this.enrollment.program_id = this.onGoingProgram.hashid;
      this.enrollment.wishlist = this.wishlist;
      axios.post("/enroll-me", this.enrollment).then((response) => {
        console.log(response);
        if (response.data.success) {
          this.onGoingProgram = response.data.data;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Enrolled and Wishlist updated to the secret santa program",
          //     icon: "EditIcon",
          //     variant: "success",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enrolled and Wishlist updated to the secret santa program',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Unable to enroll to the secret santa program",
          //     icon: "EditIcon",
          //     variant: "danger",
          //     text: response.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to enroll to the secret santa program',
                icon: 'BellIcon',
                variant: 'danger',
                text:response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      });
    },
    Draw() {
      axios
        .get("/draw-child/" + this.onGoingProgram.hashid)
        .then((response) => {
          console.log(response);
          if (response.data.success) {
            this.onGoingProgram = response.data.data;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Secret Elf Assigned",
            //     icon: "UserCheckIcon",
            //     variant: "success",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Secret Elf Assigned',
                icon: 'UserCheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            bomb.realistic();
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to Draw a Name",
            //     icon: "UserXIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to Draw a Name',
                icon: 'UserXIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

          }
        });
    },
    tasksList() {
      axios
        .get(
          "/secret-santa-task?secret_santa_program_id=" +
          this.onGoingProgram.hashid
        )
        .then((response) => {
          if (response.data.success) {
            this.tasks = response.data.data;
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fecth tasks",
            //     icon: "EditIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to fecth tasks`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          }
        })
        .catch((error) => {
          for (var err in error.response.data.errors) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error in " + err,
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: error.response.data.errors[err][0],
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text:error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    assignedTasksList() {
      axios
        .get(
          "/secret-santa-assigned-task?secret_santa_program_id=" +
          this.onGoingProgram.hashid
        )
        .then((response) => {
          if (response.data.success) {
            this.assignedTasks = response.data.data;
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fecth assigned tasks",
            //     icon: "EditIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to fecth assigned tasks`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          }
        })
        .catch((error) => {
          for (var err in error.response.data.errors) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error in " + err,
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: error.response.data.errors[err][0],
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                 
          }
        });
    },
    confirmDeleteRecordTask(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete task ${data.title}.`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-danger yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteTask(data.hashid);
        }
      });
    },
    deleteTask(id) {
      axios
        .delete("/secret-santa-task/" + id)
        .then((response) => {
          if (response.status == "204") {
            this.tasksList();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Task Deleted successfully",
            //     icon: "EditIcon",
            //     variant: "primary",
            //   },
            // });
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Task Deleted successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                  hideClose: true,
                },
              }, {
                timeout: 2000, 
                position: 'bottom-center',
                toastClassName:"successful-info",
                hideProgressBar : true,
              }); 
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to delete task",
            //     icon: "EditIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to delete task`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          }
        })
        .catch((error) => {
          for (var err in error.response.data.errors) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error in " + err,
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: error.response.data.errors[err][0],
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    updateTask() {
      if (this.task.title == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Task Title is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });

        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Task Title is Required`,
                icon:'EditIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else if (this.task.discription == "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Task Description is Required",
        //     icon: "EditIcon",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: `Task Description is Required`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
      } else {
        if (this.task.hashid != "") {
          this.task.secret_santa_program_id = this.onGoingProgram.hashid;
          axios
            .patch("/secret-santa-task/" + this.task.hashid, this.task)
            .then((response) => {
              if (response.status == "202") {
                this.tasksList();
                this.$refs["task-update"].hide();
                this.task = {
                  title: "",
                  description: "",
                  hashid: "",
                  secret_santa_program_id: "",
                };
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Task Updated successfully",
                //     icon: "EditIcon",
                //     variant: "primary",
                //   },
                // });
                this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Task Updated successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                  hideClose: true,
                },
              }, {
                timeout: 2000, 
                position: 'bottom-center',
                toastClassName:"successful-info",
                hideProgressBar : true,
              }); 
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Unable to assign task",
                //     icon: "EditIcon",
                //     variant: "danger",
                //   },
                // });

                this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to assign task`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
              }
            })
            .catch((error) => {
              for (var err in error.response.data.errors) {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Error in " + err,
                //     icon: "EditIcon",
                //     variant: "danger",
                //     text: error.response.data.errors[err][0],
                //   },
                // });

                this.$toast({
              component: ToastificationContent,
              props: {
                title:  "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            });
        } else {
          this.task.secret_santa_program_id = this.program.hashid;
          axios
            .post("/secret-santa-task", this.task)
            .then((response) => {
              if (response.status == "201") {
                this.tasksList();
                this.$refs["task"].hide();
                this.task = {
                  title: "",
                  description: "",
                  hashid: "",
                  secret_santa_program_id: "",
                };
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Task details updated successfully",
                //     icon: "EditIcon",
                //     variant: "primary",
                //   },
                // });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Task details updated successfully',
                      icon: 'CheckIcon',
                      variant: 'success',
                      hideClose: true,
                    },
                  }, {
                    timeout: 2000, 
                    position: 'bottom-center',
                    toastClassName:"successful-info",
                    hideProgressBar : true,
                  }); 
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Unable to update task details",
                //     icon: "EditIcon",
                //     variant: "danger",
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to update task details`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
              }
            })
            .catch((error) => {
              for (var err in error.response.data.errors) {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Error in " + err,
                //     icon: "EditIcon",
                //     variant: "danger",
                //     text: error.response.data.errors[err][0],
                //   },
                // });

                this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            });
        }
      }
    },
    openFile(file) {
      window.open(file.link);
    },
    respond(id, chosen) {
      axios
        .get("/secret-santa-task/" + id + "/respond/" + chosen)
        .then((response) => {
          if (response.status == "202") {
            this.assignedTasksList();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Task " + chosen + " successfully",
            //     icon: "EditIcon",
            //     variant: "primary",
            //   },
            // });
            this.$toast({
        component: ToastificationContent,
        props: {
          title: "Task " + chosen + " successfully",
          icon: 'CheckIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to delete task",
            //     icon: "EditIcon",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Unable to delete task`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          }
        })
        .catch((error) => {
          for (var err in error.response.data.errors) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error in " + err,
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: error.response.data.errors[err][0],
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error in " + err,
                icon: 'BellIcon',
                variant: 'danger',
                text: error.response.data.errors[err][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
  },
};
</script>
<style>
.secret-santa-bg-img {
  background-image: url("../../assets/gifs/xmas.gif");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.secret-santa-bg-img .card-img-overlay {
  position: relative;
  display: flex;
  padding: 20px;
}

.secret-santa-bg-img .card-img-overlay .main-card-1 {
  background: rgb(255 255 255 / 70%);
  padding: 20px 30px;
  border-radius: 0.428rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.secret-santa-bg-img .card-img-overlay .clock-are {
  padding: 0 0 0 30px;
}

.overlay-text {
  -webkit-text-fill-color: red;
  -webkit-text-stroke-color: red;
  -webkit-text-stroke-width: 1px;
}
</style>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-faq.scss";
@import "@core/scss/vue/libs/vue-select.scss";
canvas {
    z-index: 999999999999999999999999999 !important;
}
</style>