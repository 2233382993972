import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSecretSantaProgramList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/secret-santa-program", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        removeSSP(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`secret-santa-program/${id}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleSSP(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/secret-santa-programs/destroy`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    }
}